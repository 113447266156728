var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// Lib
import React from "react";
var NoVenueResults = /** @class */ (function (_super) {
    __extends(NoVenueResults, _super);
    function NoVenueResults() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getLocationsList = function () {
            if (typeof window.venueLocations === 'undefined' || !window.venueLocations.length)
                return React.createElement(React.Fragment, null);
            return (React.createElement("ul", { className: "location-grid" }, window.venueLocations.map(function (term) {
                var _a;
                return (React.createElement("li", { className: "location-grid__item", key: "location-".concat(term.term_id) },
                    React.createElement("a", { href: term.permalink, className: "location", style: { backgroundImage: "url(".concat((_a = term.imageURL) !== null && _a !== void 0 ? _a : '', ")") } },
                        React.createElement("h3", { className: "location__title" }, term.name))));
            })));
        };
        _this.render = function () {
            var otherLocations = _this.getLocationsList();
            return (React.createElement("div", { className: "no-results" },
                React.createElement("h2", { className: "h1" }, "No search results"),
                React.createElement("p", null, "Unfortunately we couldn't find venues in your search area, but here are our other locations."),
                otherLocations));
        };
        return _this;
    }
    return NoVenueResults;
}(React.PureComponent));
export default NoVenueResults;
